// import avtar from "../../assets/images/default-avatar.jpg";
const initialState = {
  // profileImage: localStorage.getItem("pI")
  //   ?? avtar,
  progressBar: 0,
  role: null,
  id: undefined,
  name: "",
  email: "",
  clientId: undefined,
  tenantId: undefined,
  iA: undefined,
  oA: undefined,
  aA: undefined,
  tI: undefined,
  tfa: false
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case '  ':
      return {
        ...state,
        tenantId: action.payload,
      };
    
    case 'ACCESS_TOKEN':
      return {
        ...state,
        aT: action.payload,
      };
    
    case 'APP_ID':
      return {
        ...state,
        app_ID: action.payload,
      };
    
    case 'LOGOUT':
      return initialState;

    default:
      return state;
  }
};

export default profileReducer;




