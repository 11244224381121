import { configureStore, combineReducers } from '@reduxjs/toolkit';
import profileReducer from './Reducer/ProfileReducer';
import userRoleReducer from './Reducer/userRoleReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';

//encryptor
const encryptor = encryptTransform({
  secretKey: 'letni$321987@Evihcra', 
  onError: function (error) {   
    console.error(error);
  },
});

const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptor], 
};

const rootReducer = combineReducers({  
  aT:profileReducer,    //accessToken 
  tI:profileReducer,  //tenantId
  app_ID:profileReducer  //appID
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export default store;


