import React, { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import NoImage from "../../assets/images/NO_Image.jpg";
import WebService from "../../Services/WebService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { app_ID } from "../../Redux/actions";
const PageHeader = () => {
  const a_token = useSelector((state: any) => state.aT.aT);
  const [accounts, setAccounts] = useState([]);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  useEffect(() => {
    let pID = localStorage.getItem('p_id')
    let type = localStorage.getItem('utype')
    let tID = localStorage.getItem('tID')
    getBrands(pID, type, tID)
  }, []);


  const onAccountChange = (account: any) => {
    dispatch(app_ID(account));
    localStorage.setItem('app_id', account);
    window.location.reload()
  }
  const getBrands = (p_id: any, u_type: any, t_id: any) => {
    return WebService.getAPI({
      action: `user-website/list?app_type=WHATSAPP-COMMERCE&p_user_id=${p_id}&user_type=${u_type}`,
      body: null,
      access_token: a_token,
      t_id: t_id
    }).then((res: any) => {
      setAccounts(res.list)
    }).catch((error: any) => {
      toast.error(error?.response?.data?.message);
      return null;
    });

  }
  return (
    <>
      <header>
        <div className="row">
          <div className="col d-flex justify-content-between py-4 px-4 py-lg-4">
            <div className=" d-none d-sm-block col-2">
              <Form.Select aria-label="Accounts" className="visually-hidden">
                <option value={'brand_name'}  >Hello</option>
                <option value={'brand_name'}  >Akash</option>
              </Form.Select>
            </div>
            <div>
              <Image
                src={localStorage.getItem("logo") ?? NoImage}
                width={50}
                height={50}
                alt="logo"
                style={{ minHeight: "60px", width: "auto", borderRadius: '50%' }}
              /></div>

            <div className="col-5 col-lg-2 align-items-center d-flex">
              <Form.Select aria-label="Accounts" onChange={(e) => onAccountChange(e.target.value)}>
                {accounts?.length > 0 &&
                  accounts?.map((res: any) => {
                    return (
                      <option value={res.id} id={res.id} key={res.id} >
                        {res.brand_name}
                      </option>
                    );
                  })}
              </Form.Select>
              <Button className="ms-2" onClick={() => {
                localStorage.clear()
                navigate('/login')
              }} >Logout</Button>
            </div>
          </div>

        </div>
      </header>
    </>
  );
};
export default PageHeader;
