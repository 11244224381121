import {
  Routes,
  Route,
  Navigate,
  RouteProps,
  RoutesProps,
} from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import { useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../components/Main";

const Navigation = () => {
  interface ProtectedRouteProps extends RoutesProps {
    authenticationPath: string;
  }
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    authenticationPath: "/login",
  };

  const Login = React.lazy(
    () => import("../components/Login/Login")
  );

  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );

  const Menu = React.lazy(
    () => import("../components/Menu/Menu")
  );
  const Details = React.lazy(
    () => import("../components/Details/Details")
  );
  const Home = React.lazy(
    () => import("../components/Home/Home")
  );

  const Events = React.lazy(
    () => import("../components/Events/Events")
  );
  const About = React.lazy(
    () => import("../components/About/About")
  );
  const Rating = React.lazy(
    () => import("../components/Rating/Rating")
  );
  const Reserve = React.lazy(
    () => import("../components/Reserve/Reserve")
  );

  const Events_Details = React.lazy(
    () => import("../components/Events_Details/Events_Details")
  );

  const Accounts = React.lazy(
    () => import("../components/Login/Accounts/account")
  );
  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                <Login />
              </Suspense>
            }
          />

          <Route
            path="/accounts"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Suspense fallback={<></>}><Accounts /></Suspense>}
              />
            }
          />

          <Route
            path="/"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<Main />}
              />
            }
          >
            {/* <Route
              path="/dashboard"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard />
                </Suspense>
              }
            />

            <Route
              path="/menu"
              element={
                <Suspense fallback={<></>}>
                  <Menu />
                </Suspense>
              }
            />
            <Route
              path="/details"
              element={
                <Suspense fallback={<></>}>
                  <Details />
                </Suspense>
              }
            />
            <Route
              path="/home"
              element={
                <Suspense fallback={<></>}>
                  <Home />
                </Suspense>
              }
            />
            <Route
              path="/events"
              element={
                <Suspense fallback={<></>}>
                  <Events />
                </Suspense>
              }
            />
            <Route
              path="/about"
              element={
                <Suspense fallback={<></>}>
                  <About />
                </Suspense>
              }
            />
            <Route
              path="/rating"
              element={
                <Suspense fallback={<></>}>
                  <Rating />
                </Suspense>
              }
            />
            <Route
              path="/events-details"
              element={
                <Suspense fallback={<></>}>
                  <Events_Details />
                </Suspense>
              }
            /> */}
            <Route
              path="/reserve"
              element={
                <Suspense fallback={<></>}>
                  <Reserve />
                </Suspense>
              }
            />
          </Route>
        </Routes >
      </div >
    </>
  );
};

export default Navigation;
