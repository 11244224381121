import { persistor } from "./store";


export const tenantId = (tenantId) => ({
  type: 'TENANT_ID',
  payload: tenantId,
});
export const pId = (pId) => ({
  type: 'PID',
  payload: pId,
});

export const aT = (aT) => ({
  type: 'ACCESS_TOKEN',
  payload: aT,
});
export const app_ID = (app_ID) => ({
  type: 'APP_ID',
  payload: app_ID,
});

export const logout = () => (dispatch, getState) => {
  dispatch({ type: 'LOGOUT' });
  persistor.purge();
};




