import * as React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Navigation from "./navigations";
import { ToastContainer } from "react-toastify";
import { Provider } from 'react-redux';
import store, { persistor } from "./Redux/store";
import { PersistGate } from 'redux-persist/integration/react';
const App = () => {
  // React.useEffect(() => {
  //   getUserRestaurantAppSettings().then((res: any) => {
  //     if (res?.setting?.domain !== null)
  //       localStorage.setItem("sub_domain", res?.setting?.domain?.sub_domain);
  //     const backgroundImage = res?.setting?.background_image;
  //     if (backgroundImage) {
  //       document.body.style.backgroundImage = `url('${backgroundImage}')`;
  //     }
  //   });
  // }, []);
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <Navigation />
            <ToastContainer />
          </div>
        </PersistGate>
      </Provider>
    </>
  );
};
export default App;
